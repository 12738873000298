<template>
  <custom-modal
    :name="name"
    @before-close="withoutPaymentOptionDialog.cancel()"
  >
    <Form class="without-payment-option-dialog" v-slot="{ invalid }">
      <div class="without-payment-option-dialog__title">
        Choose payment method
      </div>
      <div v-if="selectedGroup && maxPersonsToDeposit > 0">
        <RadioGroup
          v-model="isPayFull"
          label="Select the number of participants to pay for"
          :options="radioOptions"
          :isRow="true"
          variant="black"
          class="mb-16"
        />
        <template v-if="isPayFull.value === 'persons'">
          <FormItem
            :rules="{
              required: true,
              decimal: 2,
              maxValue: maxPersonsToDeposit,
              minValue: 0.01,
            }"
            class="mb-24"
          >
            <Input
              v-model="personsToDeposit"
              :mask="numberMask"
              label="Number of participants to pay for"
              placeholder="Input number of participants to pay for"
              is-small
              @blur="handleBlurPersons"
            />
          </FormItem>
          <p class="without-payment-option-dialog__paymentAmount">
            Payment amount: ${{ paymentAmount || 0 }}
          </p>
        </template>
        <template v-else-if="isPayFull.value === 'amount'">
          <FormItem
            :rules="{
              required: true,
              decimal: 2,
              maxValue: paymentDueAmount / 100,
              minValue: 0.01,
            }"
            class="mb-24"
          >
            <div class="without-payment-option-dialog__amountToCollect">
              <Input
                v-model="amountToCollect"
                label="Amount to collect"
                placeholder="Input amount to collect"
                is-small
                :mask="numberDecimalMask"
                :is-readonly="isSplitPayment"
                @blur="handleBlurAmountToCollect"
              />
              <Icon
                name="calculator"
                :color="isSplitPayment ? 'primary' : 'secondary-400'"
                is-clickable
                @click="isSplitPayment = !isSplitPayment"
              />
            </div>
          </FormItem>
          <div
            v-if="isSplitPayment"
            class="without-payment-option-dialog__split mb-24"
          >
            <FormItem
              :rules="{
                required: true,
                numeric: true,
                minValue: 1,
              }"
            >
              <Input
                v-model="splitIntoPayments"
                label="Split into"
                :mask="numberMask"
                is-small
                @blur="handleBlurSplitting"
              />
            </FormItem>
            <FormItem
              :rules="{
                required: true,
                numeric: true,
                minValue: 1,
                maxValue: splitIntoPayments,
              }"
            >
              <Input
                v-model="applyPayments"
                label="Apply"
                :mask="numberMask"
                is-small
                @blur="handleBlurSplitting"
              />
            </FormItem>
          </div>
        </template>
        <div
          v-if="checkoutInfo"
          class="without-payment-option-dialog__breakdown"
        >
          <div
            v-if="pricePerPerson"
            class="without-payment-option-dialog__breakdown__row"
          >
            <span>Price per person (incl. tax):</span>
            <span>{{ getPrice(pricePerPerson) }}</span>
          </div>
          <div
            v-if="alreadyDepositedPersons"
            class="without-payment-option-dialog__breakdown__row m-green"
          >
            <span>Already deposited persons:</span>
            <span>{{ alreadyDepositedPersons }}</span>
          </div>
          <div
            v-if="checkoutInfo.paid"
            class="without-payment-option-dialog__breakdown__row m-green"
          >
            <span>Paid:</span>
            <span>{{ getPrice(checkoutInfo.paid) }}</span>
          </div>
          <div
            v-if="paymentDueAmount"
            class="without-payment-option-dialog__breakdown__row m-red"
          >
            <span>Payment due:</span>
            <span>{{ getPrice(paymentDueAmount) }}</span>
          </div>
        </div>
        <RadioGroup
          v-if="savedCardOptions.length > 1"
          v-model="selectedSavedCard"
          label="Select saved card for auto payment"
          :options="savedCardOptions"
          :isRow="true"
          variant="black"
          class="mt-16"
        />
      </div>
      <div class="without-payment-option-dialog__actions">
        <template v-if="hasStripe">
          <Button
            is-block
            isSmall
            :is-disabled="
              invalid || !selectedSavedCard || !selectedSavedCard.value
            "
            @click="
              withoutPaymentOptionDialog.ok({
                option: undefined,
                personsToDeposit: +personsToDeposit,
                amountToCollect: +amountToCollect * 100,
                isSkipPayment: false,
                paymentMethodId: selectedSavedCard && selectedSavedCard.value,
              })
            "
          >
            Charge {{ selectedSavedCard?.name || "saved card" }}
          </Button>
          <Button
            is-block
            isSmall
            variant="secondary"
            :is-disabled="invalid"
            @click="
              withoutPaymentOptionDialog.ok({
                option: undefined,
                personsToDeposit: +personsToDeposit,
                amountToCollect: +amountToCollect * 100,
                isSkipPayment: false,
              })
            "
          >
            Charge another card
          </Button>
        </template>
        <Button
          v-else
          is-block
          isSmall
          :is-disabled="invalid"
          @click="
            withoutPaymentOptionDialog.ok({
              option: undefined,
              personsToDeposit,
              amountToCollect: +amountToCollect * 100,
              isSkipPayment: true,
            })
          "
        >
          Leave as payment due
        </Button>
        <Button
          v-for="(methodName, index) in paymentMethods"
          :key="index"
          is-block
          isSmall
          isOutlined
          :is-disabled="invalid"
          @click="
            withoutPaymentOptionDialog.ok({
              option: methodName,
              personsToDeposit: +personsToDeposit,
              amountToCollect: +amountToCollect * 100,
              isSkipPayment: true,
            })
          "
        >
          Customer paid with {{ methodName }}
        </Button>
        <Button
          is-block
          isOutlined
          variant="secondary"
          isSmall
          @click="withoutPaymentOptionDialog.cancel()"
        >
          Back
        </Button>
      </div>
    </Form>
  </custom-modal>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { DEFAULT_PAYMENT_METHODS } from "@/helpers/const";
import { capitalize } from "@/helpers/utils";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const state = Vue.observable({
  active: false,
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));

const reset = () => {
  state.active = false;
};

export const withoutPaymentOptionDialog = {
  get state() {
    return state;
  },

  open() {
    state.active = true;
    return dialogPromise();
  },

  ok({
    option,
    personsToDeposit,
    amountToCollect,
    isSkipPayment = true,
    paymentMethodId,
  }) {
    close({
      option,
      personsToDeposit,
      amountToCollect,
      isSkipPayment,
      paymentMethodId,
    });
    reset();
  },

  cancel() {
    close({ option: null });
    reset();
  },
};

export default {
  name: "WithoutPaymentOptionDialog",
  props: {
    hasStripe: {
      type: Boolean,
      default: false,
    },
    selectedGroup: {
      type: Object,
      default: null,
    },
    checkoutInfo: {
      type: Object,
      default: null,
    },
    savedCards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const radioOptions = [
      {
        id: 1,
        name: "Pay full payment due",
        value: "full",
      },
      {
        id: 2,
        name: "Specify number of participants",
        value: "persons",
      },
      {
        id: 3,
        name: "Specify amount to collect",
        value: "amount",
      },
    ];
    return {
      withoutPaymentOptionDialog,
      name: "withoutPaymentOptionDialog",
      radioOptions,
      isPayFull: radioOptions[0],
      selectedSavedCard: null,
      personsToDeposit: "",
      paymentAmount: 0,
      isSplitPayment: false,
      amountToCollect: "",
      splitIntoPayments: "",
      applyPayments: "",
    };
  },
  computed: {
    ...mapState({
      paymentMethods: (state) => {
        const selectedVenue = state.venues.selectedVenue;
        return (selectedVenue?.paymentMethods || DEFAULT_PAYMENT_METHODS)
          .filter((method) => method.isActive)
          .map((method) => method.name);
      },
    }),
    numberDecimalMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: "",
      });
    },
    numberMask() {
      return createNumberMask({
        allowDecimal: false,
        includeThousandsSeparator: false,
        prefix: "",
      });
    },
    isActive() {
      return this.withoutPaymentOptionDialog.state.active;
    },
    maxPersonsToDeposit() {
      return this.selectedGroup
        ? this.selectedGroup.maximum - (this.alreadyDepositedPersons || 0)
        : null;
    },
    paymentDueAmount() {
      return this.checkoutInfo
        ? Math.max(
            this.checkoutInfo.total -
              ((this.checkoutInfo.paid || 0) -
                (this.checkoutInfo.refunded || 0)),
            0
          )
        : 0;
    },
    alreadyDepositedPersons() {
      if (!this.checkoutInfo || !this.selectedGroup) {
        return false;
      }

      return (
        Math.round(
          (this.checkoutInfo.paid /
            (this.checkoutInfo.subtotal + this.taxesTotalAmount)) *
            this.selectedGroup.maximum *
            100
        ) / 100
      );
    },
    taxesTotalAmount() {
      if (!this.checkoutInfo) return false;
      return this.checkoutInfo.taxes.reduce(
        (prev, tax) => prev + tax.amount,
        0
      );
    },
    pricePerPerson() {
      return this.checkoutInfo && this.selectedGroup
        ? (this.checkoutInfo.subtotal +
            this.checkoutInfo.taxes.reduce(
              (prev, tax) => prev + tax.amount,
              0
            )) /
            this.selectedGroup.maximum
        : 0;
    },
    savedCardOptions() {
      return (
        this.savedCards?.map((card) => ({
          id: card.id,
          name: `${capitalize(card.brand)} *${card.last4}`,
          value: card.id,
        })) || []
      );
    },
  },
  watch: {
    isPayFull(newValue) {
      this.isSplitPayment = false;
      this.splitIntoPayments = "";
      this.applyPayments = "";
      this.paymentAmount = 0;
      this.personsToDeposit = "";
      this.amountToCollect = "";

      if (newValue === "persons") {
        this.personsToDeposit = this.maxPersonsToDeposit;
      } else if (newValue === "amount") {
        this.amountToCollect = this.paymentDueAmount;
      }
    },
    isSplitPayment(newValue) {
      if (newValue) {
        this.splitIntoPayments = 2;
        this.applyPayments = 1;
      }
    },
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    },
    personsToDeposit() {
      this.handleBlurPersons();
    },
    splitIntoPayments() {
      this.handleBlurSplitting();
    },
    applyPayments() {
      this.handleBlurSplitting();
    },
    savedCardOptions(newValue) {
      this.selectedSavedCard = newValue[0] || null;
    },
  },
  methods: {
    getPrice(amount) {
      return amount
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(Math.abs(amount) / 100)
        : "-";
    },
    handleBlurPersons() {
      const clearPersons = +this.personsToDeposit
        .toString()
        .replaceAll(/[^\d.]/g, "");
      this.personsToDeposit = Math.round(clearPersons * 100) / 100;
      this.paymentAmount =
        Math.round(+this.personsToDeposit * this.pricePerPerson) / 100;
    },
    handleBlurAmountToCollect() {
      const clearAmount = +this.amountToCollect
        .toString()
        .replaceAll(/[^\d.]/g, "");
      this.amountToCollect = Math.round(clearAmount * 100) / 100;
    },
    handleBlurSplitting() {
      const clearSplitInto = Math.round(
        +this.splitIntoPayments.toString().replaceAll(/\D/g, "")
      );
      const clearApply = Math.round(
        +this.applyPayments.toString().replaceAll(/\D/g, "")
      );
      if (clearApply > 0 && clearSplitInto > 0) {
        this.amountToCollect =
          Math.round((this.paymentDueAmount / clearSplitInto) * clearApply) /
          100;
      } else {
        this.amountToCollect = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.vm--modal {
  max-height: 98vh;
  overflow: auto;
}
</style>

<style lang="scss" scoped>
.without-payment-option-dialog {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $secondary-500;
    text-align: center;
  }

  &__message {
    font-size: 16px;
    line-height: 28px;
    color: $secondary-500;
    text-align: center;
  }

  &__breakdown {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      &.m-red {
        color: $danger;
      }
      &.m-green {
        color: $success;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__paymentAmount {
    font-weight: 500;
    font-size: 18px;
  }

  &__amountToCollect {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    > :first-child {
      flex: 1;
    }

    > :last-child {
      margin: 12px 8px;
    }
  }

  &__split {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
</style>
